<template>
    <basic-layout class="ma-4">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <user-info/>
                <div class="pa-3 mt-10">
                    <v-row  align="center" justify="center" >
                        <v-col>
                            <v-card @click="goToPayslip" color="primary" dark>
                                <v-card-text>
                                    <v-row align="center" justify="center">
                                        <v-col align="center" justify="center">
                                            <v-icon  x-large>
                                                mdi-cash-multiple
                                            </v-icon>
                                            <h3 color="#ffffff" class="mt-2">Payslip</h3>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card @click="goToWebsite" color="primary" dark>
                                <v-card-text>
                                    <v-row align="center" justify="center">
                                        <v-col align="center" justify="center">
                                            <v-icon  x-large>
                                                mdi-web
                                            </v-icon>
                                            <h3 class="mt-2">Website</h3>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row  align="center" justify="center" >
                        <v-col>
                            <v-card @click="goToProfile" color="primary" dark>
                                <v-card-text>
                                    <v-row align="center" justify="center">
                                        <v-col align="center" justify="center">
                                            <v-icon  x-large>
                                                mdi-account-outline
                                            </v-icon>
                                            <h3 class="mt-2">Profile</h3>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card @click="goToChangePassword" color="primary" dark>
                                <v-card-text>
                                    <v-row align="center" justify="center">
                                        <v-col align="center" justify="center">
                                            <v-icon  x-large>
                                                mdi-security
                                            </v-icon>
                                            <h3 class="mt-2">Security</h3>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row  align="center" justify="center" >
                        <v-col>
                            <v-card  @click="goToAbout" color="primary" dark>
                                <v-card-text>
                                    <v-row align="center" justify="center">
                                        <v-col align="center" justify="center">
                                            <v-icon  x-large>
                                                mdi-information-outline
                                            </v-icon>
                                            <h3 class="mt-2">About</h3>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card @click="goToPolicy" color="primary" dark>
                                <v-card-text>
                                    <v-row align="center" justify="center">
                                        <v-col align="center" justify="center">
                                            <v-icon  x-large>
                                                mdi-file-document
                                            </v-icon>
                                            <h3 class="mt-2">Policy</h3>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </basic-layout>
</template>

<script>

    export default {
        name: "Home",
        methods: {
            goToPayslip(){
                this.$router.push({name: "PayslipList"})
            },
            goToChangePassword(){
                this.$router.push({name: "ChangePassword"})
            },
            goToProfile(){
                this.$router.push({name: "Profile"})
            },
            goToPolicy(){
                this.$router.push({name: "Policy"})
            },
            goToWebsite(){
                window.location.href = this.$store.getters.getWebLink;
            },
            goToAbout(){
                this.$router.push({name: "About"})
            }
        },
        created(){
            this.$store.commit('setAlert','');
        }
    }
</script>

<style scoped>

</style>